import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import useStyles from "../styles";
//import { Helmet } from "react-helmet";

const NoThankYou = () => {
  const classes = useStyles();
  return (
    <div className="body">
      <Container maxWidth="md">
        <div className={classes.bodyStyle}>
          <Typography
            className={classes.typographyStyle}
            variant="h4"
            align="center"
            gutterBottom
          >
            Takk for at du registrerer dig!
          </Typography>
          <Typography
            className={classes.typographyStyle}
            variant="h6"
            align="center"
            gutterBottom
          >
            Vi sender dig en e-post med billetten.
          </Typography>
          <Typography
            className={classes.typographyStyle}
            align="center"
            gutterBottom
          >
            <Button href="https://sipp.no/" variant="contained" color="primary">
              Tillbake till messen
            </Button>
          </Typography>
        </div>
      </Container>
      {/* <Helmet>
        <script src="../tracktag.js" type="text/javascript" />
      </Helmet> */}
    </div>
  );
};

export default NoThankYou;
