import React from "react";

import logo from "../../images/AGI_logo_webb.png";
import useStyles from "../styles";

const Header = () => {
  const classes = useStyles();
  return (
    <header className={classes.headerBackground}>
      <a href="https://event.agi.se/">
        <img src={logo} alt="logo" className={classes.headerImgSize} />
      </a>
      <hr className="solid"></hr>
    </header>
  );
};

export default Header;
