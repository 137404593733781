import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import "react-phone-input-2/lib/material.css";
import {
  FormGroup,
  FormControl,
  CardContent,
  Card,
  Typography,
  Paper,
  Container,
  Grid,
  Link,
} from "@material-ui/core";
import * as yup from "yup";
import { TextFormField } from "./common/textFormField";
import { SelectFormField } from "./common/selectFormField";
import { RadioField } from "./common/radioField";
import BannerCheckbox from "./common/bannerCheckbox";
import ErrorText from "./common/errorText";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useStyles from "./styles";

const exhibitionId = "sopse295842";
const userCategory = "visitor";

const options = [
  { label: "Promotion", value: "promotion" },
  { label: "Material", value: "material" },
  { label: "Förpackning", value: "förpackning" },
  { label: "Screen", value: "screen" },
  { label: "Storformat", value: "storformat" },
  { label: "Skylt", value: "skylt" },
  { label: "Tryckeri", value: "tryckeri" },
  { label: "Konvertering", value: "konvertering" },
  { label: "Bokbinderi", value: "bokbinderi" },
  { label: "Färdiggörning", value: "färdiggörning" },
  { label: "Digitaltryck", value: "Digitaltryck" },
];

const countryCodeRegex = /^(\+?\d{1,3}|\d{1,4})$/;

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Skriv en giltig e-postadress")
    .required("Fältet är obligatoriskt"),
  firstName: yup
    .string()
    .min(2, "Förnamnet måste innehålla minst 2 tecken")
    .max(50, "Förnamnet får innehålla högst 50 tecken")
    .required("Fältet är obligatoriskt"),
  lastName: yup
    .string()
    .min(2, "Efternamnet måste innehålla minst 2 tecken")
    .max(50, "Efternamnet får innehålla högst 50 tecken")
    .required("Fältet är obligatoriskt"),
  phone: yup
    .string()
    .min(5, "Ange ett giltigt telefonnummer")
    .max(20, "Ange ett giltigt telefonnummer")
    .required("Fältet är obligatoriskt"),
  countryCode: yup
    .string()
    .min(2, "Landskoden måste innehålla minst en siffra")
    .max(4, "Landskoden får innehålla max 3 siffror")
    .matches(countryCodeRegex, "Skriv en giltig landskod")
    .required("Fältet är obligatoriskt"),
  company: yup
    .string()
    .min(2, "Företagsnamnet måste innehålla minst 2 tecken")
    .max(50, "Företagsnamnet får innehålla högst 50 tecken")
    .required("Fältet är obligatoriskt"),
  addressField: yup
    .string()
    .min(2, "Adressfältet måste innehålla minst 2 tecken")
    .max(60, "Adressfältet får innehålla högst 60 tecken")
    .required("Fältet är obligatoriskt"),
  zipcode: yup
    .string()
    .min(3, "Skriv ett giltigt postnummer")
    .max(16, "Postnumret får innehålla högst 16 tecken")
    .required("Fältet är obligatoriskt"),
  city: yup
    .string()
    .min(2, "Stadsnamnet måste innehålla minst 2 tecken")
    .max(40, "Stadsnamnet får innehålla högst 40 tecken")
    .required("Fältet är obligatoriskt"),
  decisionMaker: yup.string().required("Välj ett alternativ"),
  investing: yup.string().required("Välj ett alternativ"),
  interests: yup.array().min(1, "Välj minst ett alternativ"),
  position: yup.string().required("Fältet är obligatoriskt"),
  terms: yup.boolean().oneOf([true], "Villkoren måste godkännas"),
});

function formatDate() {
  return moment().format("YYYY-MM-DD  HH:mm:ss");
}

const RegistrationForm = () => {
  const classes = useStyles();
  let history = useHistory();
  return (
    <Formik
      initialValues={{
        userCategory: "",
        exhibitionId: "",
        registeredAtDate: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        countryCode: "",
        company: "",
        addressField: "",
        zipcode: "",
        city: "",
        position: "",
        decisionMaker: "",
        investing: "",
        interests: [],
        terms: false,
        newsletter: false,
        //        group: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true);
        data.registeredAtDate = formatDate();
        data.firstName = data.firstName.trim();
        data.lastName = data.lastName.trim();
        data.email = data.email.trim();
        data.exhibitionId = exhibitionId;
        data.userCategory = userCategory;

        console.log("Submit: ", data);
        //http://localhost:9000/db
        //https://api.sipp.no/db
        //https://api.emjoevents.com/db
        axios.post("https://api.emjoevents.com/db", data).then((response) => {
          console.log("Here is the response" + response.data);
          if (
            !response.data.exists &&
            response.data.addedToDb &&
            !response.data.banned
          ) {
            setSubmitting(false);
            history.push("/se/thank-you");
          } else if (
            response.data.exists &&
            !response.data.addedToDb &&
            !response.data.banned
          ) {
            setSubmitting(false);
            history.push("/se/already-registered");
          } else if (
            !response.data.exists &&
            !response.data.addedToDb &&
            response.data.banned
          ) {
            setSubmitting(false);
            history.push("/se/oops");
          } else {
            setSubmitting(false);
            history.push("/something-went-wrong");
          }
        });
        //SEND POST REQUEST TO API
        // axios
        //   .post("http://localhost:9000/testAPI", data)
        //   .then((response) => {
        //     console.log(response);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });

        //setSubmitting(false);
        //history.push("/thankyou");
      }}
    >
      {({ values, isSubmitting, errors }) => {
        return (
          <div className={classes.form}>
            <Container maxWidth="md">
              <Paper className={classes.topPaper}>
                <Typography variant="h5" gutterBottom>
                  Välkommen till Sign&Print Scandinavia 2023
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Sign&Print Scandinavia välkomnar alla kunder – direkta och
                  indirekta – till utställarna.
                </Typography>
                <Typography>
                  Är du leverantör och vill vara med på mässan, så kan du boka
                  din monter&nbsp;
                  <Link
                    href="https://www.signochprint.se/hallplan/"
                    underline="hover"
                  >
                    här
                  </Link>
                  .
                </Typography>
              </Paper>
              <Paper className={classes.paper}>
                <Form>
                  <Typography variant="h5" gutterBottom>
                    Registrera dig till Sign&Print Scandinavia 2023
                  </Typography>
                  <Typography variant="body2">
                    Tänk på att du inte kan registrera flera besökare på samma
                    e-postadress.
                  </Typography>

                  <Field
                    name="email"
                    label="E-post"
                    component={TextFormField}
                  />

                  <Field
                    name="firstName"
                    label="Förnamn"
                    component={TextFormField}
                  />
                  <Field
                    name="lastName"
                    label="Efternamn"
                    component={TextFormField}
                  />
                  <Field
                    name="company"
                    label="Företag"
                    component={TextFormField}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={3}>
                      <Field
                        name="countryCode"
                        label="Landskod"
                        component={TextFormField}
                        autoComplete="new-password"
                        helperText="ex. +46"
                        type="tel"
                      />
                    </Grid>
                    <Grid item xs={7} sm={9}>
                      <Field
                        name="phone"
                        label="Telefonnummer"
                        component={TextFormField}
                        autoComplete="new-password"
                        type="number"
                        className={classes.input}
                      />
                    </Grid>
                  </Grid>

                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="addressField"
                            label="Adress"
                            component={TextFormField}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Field
                            name="zipcode"
                            label="Postnummer"
                            component={TextFormField}
                          />
                        </Grid>
                        <Grid item xs={6} sm={9}>
                          <Field
                            name="city"
                            label="Stad"
                            component={TextFormField}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Field
                    options={[
                      {
                        label: "VD / Ägare / Styrelse",
                        value: "vd-ägare-styrelse",
                      },
                      { label: "Produktionschef", value: "produktionschef" },
                      {
                        label: "Marknad / Kommunikation",
                        value: "marknad-kommunikation",
                      },
                      { label: "IT / IT-chef", value: "it-chef" },
                      { label: "Kreativ", value: "kreativ" },
                      {
                        label: "Grafisk formgivare",
                        value: "grafisk-formgivare",
                      },
                      {
                        label: "Prepress medarbetare",
                        value: "prepress-medarbetare",
                      },
                      { label: "Tryckeri", value: "tryckeri" },
                      { label: "Efterbehandling", value: "efterbehandling" },
                      { label: "Logistik", value: "logistik" },
                      { label: "Säljare", value: "säljare" },
                    ]}
                    label="Position"
                    name="position"
                    component={SelectFormField}
                  />
                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <Typography variant="h5">Jag...</Typography>
                      <RadioField
                        name="decisionMaker"
                        type="radio"
                        label="Är beslutsfattare"
                        value="yes"
                      />
                      <RadioField
                        name="decisionMaker"
                        type="radio"
                        label="Påverkar beslut"
                        value="influence"
                      />
                      <RadioField
                        name="decisionMaker"
                        type="radio"
                        label="Påverkar inte beslut"
                        value="no"
                      />
                      <ErrorMessage
                        name="decisionMaker"
                        component={ErrorText}
                      />
                    </CardContent>
                  </Card>
                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <Typography variant="h5">Mitt företag...</Typography>
                      <RadioField
                        name="investing"
                        type="radio"
                        label="Planerar att investera år 2023"
                        value="planToInvest2023"
                      />
                      <RadioField
                        name="investing"
                        type="radio"
                        label="Planerar att investera år 2024"
                        value="planToInvest2024"
                      />
                      <RadioField
                        name="investing"
                        type="radio"
                        label="Planerar inte att investera"
                        value="dontPlanToInvest"
                      />
                      <ErrorMessage name="investing" component={ErrorText} />
                    </CardContent>
                  </Card>
                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <FormControl>
                        <Typography variant="h5">Intressen</Typography>
                        <Typography variant="subtitle1">
                          Vad är du intresserad av att se på mässan? Markera
                          gärna flera alternativ!
                        </Typography>
                        <FormGroup className={classes.formgroup}>
                          {options.map((opt) => (
                            <Field
                              type="checkbox"
                              color="primary"
                              component={CheckboxWithLabel}
                              name="interests"
                              key={opt.value}
                              value={opt.value}
                              Label={{ label: opt.label }}
                            />
                          ))}
                        </FormGroup>
                        <ErrorMessage name="interests" component={ErrorText} />
                      </FormControl>
                    </CardContent>
                  </Card>
                  <div></div>
                  <Field
                    className={classes.checkbox}
                    name="terms"
                    label="Godkänn villkoren"
                    subLabel="Jag godkänner att mina uppgifter kan användas för att få utskick av arrangören. Jag kan också bli kontaktad av utställare och företag som skannat min besökarbricka under mässan. Mina uppgifter kan också sammanställas i anonym form och användas i marknadsföringssyfte. Översiktsbilder som tas under mässans gång kan användas i redaktionellt innehåll och marknadsföringssyfte av arrangören.
                "
                    component={BannerCheckbox}
                  />
                  <div></div>
                  <Button
                    className={classes.button}
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Skicka
                  </Button>
                  <div></div>
                  {/* <Field
                name="group"
                label="Select your interests"
                options={[
                  { label: "Interest1", value: "interest1" },
                  { label: "Interest2", value: "interest2" },
                  { label: "Interest3", value: "interest3" },
                ]}
                component={GroupCheckbox}
              /> */}
                  {/* <h3>Values</h3>
                  <pre>{JSON.stringify(values, 0, 2)}</pre>
                  <pre>{JSON.stringify(errors, 0, 2)}</pre> */}
                </Form>
              </Paper>
            </Container>
          </div>
        );
      }}
    </Formik>
  );
};

export default RegistrationForm;
