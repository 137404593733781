import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import useStyles from "../styles";

const DkBanPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.bodyStyle}>
      <Container maxWidth="md">
        <Typography
          className={classes.typographyStyle}
          variant="h5"
          align="center"
          gutterBottom
        >
          Ups, du har registreret en leverandøradresse.
        </Typography>
        <Typography
          className={classes.typographyStyle}
          variant="body1"
          align="center"
          gutterBottom
        >
          Er du leverandør og vil være med på messen? Så er du velkommen at
          reservere en stand.
        </Typography>
        <Typography
          className={classes.typographyStyle}
          align="center"
          gutterBottom
        >
          <Button
            href="https://sipp.dk/halplan/"
            variant="contained"
            color="primary"
          >
            Book en stand her
          </Button>
        </Typography>
      </Container>
    </div>
  );
};

export default DkBanPage;
