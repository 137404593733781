import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import useStyles from "../styles";
import { useHistory } from "react-router-dom";

const DkAlreadyRegistered = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.bodyStyle}>
      <Container maxWidth="md">
        <Typography
          className={classes.typographyStyle}
          variant="h5"
          align="center"
          gutterBottom
        >
          Denne e-mailadresse er allerede tilmeldt messen Sign&Print Denmark
          2024!
        </Typography>
        <Typography
          className={classes.typographyStyle}
          variant="body1"
          align="center"
          gutterBottom
        >
          Bemærk at du ikke kan registrere flere besøgende på samme
          e-mailadresse.
        </Typography>
        <Typography
          className={classes.typographyStyle}
          align="center"
          gutterBottom
        >
          <Button
            onClick={() => {
              history.push("/dk/register");
            }}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Prøv igen
          </Button>
          <Button href="https://sipp.dk/" variant="outlined" color="primary">
            Til messen
          </Button>
        </Typography>
      </Container>
    </div>
  );
};

export default DkAlreadyRegistered;
