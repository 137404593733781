import { common, grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Paper.A400,
  },
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
  },
  headerImgSize: {
    height: "50px",
    padding: "10px",
  },
  paper: {
    padding: "20px",
    marginBottom: "80px",
  },
  topPaper: {
    padding: "20px",
    marginBottom: "20px",
  },
  button: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    display: "block",
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  footer: {
    height: "4vh",
    background: grey.A400,
    padding: theme.spacing(2, 2, 6),
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  footerText: {
    color: common.white,
  },
  form: {
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px",
  },
  bodyStyle: {
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px",
    paddingBottom: "400px",
  },
  formgroup: {
    marginLeft: 10,
  },
  selectForm: {
    marginBottom: 20,
  },
  groupCheckbox: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControlLabelCustom: {
    width: "auto",
  },
  checkbox: {
    padding: "20px",
    margin: "0px",
    width: "auto",
  },
  typographyStyle: {
    paddingBottom: "40px",
  },
  errText: {
    color: "#f44336",
    fontSize: "0.75rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontVariant: "normal",
  },
  cardMedia: {
    width: "auto",
    padding: "20px 0px 20px 0px",
    objectFit: "contain !important",
  },
  eventCard: { marginTop: 20 },
  chooseButton: {
    width: "100%",
  },
  dynamicCard: {
    marginBottom: "20px",
    flexWrap: "nowrap",
  },
  dynamicButton: {
    float: "right",
  },
  cardBottom: {
    marginBottom: "100px",
  },
  startPage: {
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px",
    marginBottom: "450px",
  },
}));

export default useStyles;
