import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  ThemeProvider,
  Typography,
  Container,
  Paper,
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { array, boolean, object, string } from "yup";
import * as yup from "yup";
import { theme } from "../theme";
import useStyles from "../styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { SelectFormField } from "../common/selectFormField";
import { TextFormField } from "../common/textFormField";
import BannerCheckbox from "../common/bannerCheckbox";

const exhibitionId = "sopdk793058";

const options = [
  {
    label: "A.P.A. S.p.A.",
    value: "A.P.A. S.p.A.",
  },
  {
    label: "ACG Nyström",
    value: "ACG Nyström",
  },
  {
    label: "ACP Systems",
    value: "ACP Systems",
  },
  {
    label: "Addbrand",
    value: "Addbrand",
  },
  {
    label: "AGFA",
    value: "AGFA",
  },
  {
    label: "AMB Visuals",
    value: "AMB Visuals",
  },
  {
    label: "Antalis",
    value: "Antalis",
  },
  {
    label: "Artwork Systems",
    value: "Artwork Systems",
  },
  {
    label: "Bendixen",
    value: "Bendixen",
  },
  {
    label: "Canon",
    value: "Canon",
  },
  {
    label: "Danish Connection",
    value: "Danish Connection",
  },
  {
    label: "Dansk Alvøen",
    value: "Dansk Alvøen",
  },
  {
    label: "Dansk Skilte Inventar",
    value: "Dansk Skilte Inventar",
  },
  {
    label: "Dataline",
    value: "Dataline",
  },
  {
    label: "DNR Sign",
    value: "DNR Sign",
  },
  {
    label: "Domino Systems",
    value: "Domino Systems",
  },
  {
    label: "EWJ",
    value: "EWJ",
  },
  {
    label: "gop Danmark",
    value: "gop Danmark",
  },
  {
    label: "Grakom (FESPA Denmark)",
    value: "Grakom (FESPA Denmark)",
  },
  {
    label: "H-Tryck",
    value: "H-Tryck",
  },
  {
    label: "HAIKOM",
    value: "HAIKOM",
  },
  {
    label: "Heidelberg",
    value: "Heidelberg",
  },
  {
    label: "Indukta",
    value: "Indukta",
  },
  {
    label: "Intervlag",
    value: "Intervlag",
  },
  {
    label: "Konica Minolta",
    value: "Konica Minolta",
  },
  {
    label: "L-ShopTeam",
    value: "L-ShopTeam",
  },
  {
    label: "Laboprint",
    value: "Laboprint",
  },
  {
    label: "Lasertryk",
    value: "Lasertryk",
  },
  {
    label: "Leasing Fyn Bank",
    value: "Leasing Fyn Bank",
  },
  {
    label: "ModulMounter",
    value: "ModulMounter",
  },
  {
    label: "Print.com",
    value: "Print.com",
  },
  {
    label: "Ricoh",
    value: "Ricoh",
  },
  {
    label: "Scanteam",
    value: "Scanteam",
  },
  {
    label: "Schur Technology",
    value: "Schur Technology",
  },
  {
    label: "Seripress",
    value: "Seripress",
  },
  {
    label: "SG Access",
    value: "SG Access",
  },
  {
    label: "Signcom",
    value: "Signcom",
  },
  {
    label: "Sign Print & Pack",
    value: "Sign Print & Pack",
  },
  {
    label: "Skilt & Print Branchen",
    value: "Skilt & Print Branchen",
  },
  {
    label: "Skovtex",
    value: "Skovtex",
  },
  {
    label: "Sollex",
    value: "Sollex",
  },
  {
    label: "TECH Aalborg",
    value: "TECH Aalborg",
  },
  {
    label: "Viewnet",
    value: "Viewnet",
  },
  {
    label: "Vikiallo",
    value: "Vikiallo",
  },
  {
    label: "Vink Plast",
    value: "Vink Plast",
  },
  {
    label: "Xerox",
    value: "Xerox",
  },
  {
    label: "Yellotools",
    value: "Yellotools",
  },
  {
    label: "Zünd",
    value: "Zünd",
  },
];

const emptyAttendeeList = {
  exhibitionId: exhibitionId,
  userCategory: "exhibitor",
  company: "",
  registeredAtDate: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

const validationSchema = yup.object({
  company: string()
    .required("Company name is required.")
    .min(2, "Your name needs to be at least 3 characters"),
  termsAndConditions: boolean()
    .required("Terms and conditions must be accepted.")
    .isTrue("Terms and conditions must be accepted."),
  attendees: array(
    object({
      firstName: string()
        .required("First name required.")
        .min(2, "First name needs to be at least 2 characters")
        .max(20, "First name needs to be at most 20 characters"),
      lastName: string()
        .min(2, "Last name must be at least 2 characters")
        .max(20, "Last name needs to be at most 20 characters")
        .required("Last name is required."),
      email: string()
        .email("Enter a valid email")
        .required("E-mail is required."),
    })
  ).min(1, "You need to register at least 1 exhibitor"),
});

function formatDate() {
  return moment().format("YYYY-MM-DD  HH:mm:ss");
}

const DkExhibitorForm = () => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.form}>
        <Container maxWidth="md">
          <Card className={classes.cardBottom}>
            <CardContent>
              <Formik
                initialValues={{
                  company: "",
                  registeredAtDate: "",
                  termsAndConditions: false,
                  attendees: [emptyAttendeeList],
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  data.registeredAtDate = formatDate();

                  console.log("Submit: ", data);
                  //http://localhost:9000/exhibitor
                  //https://api.sipp.no/exhibitor
                  //https://api.emjoevents.com/exhibitor
                  axios
                    .post("https://api.emjoevents.com/exhibitor", data)
                    .then((response) => {
                      console.log("Here is the response" + response.data);
                      if (!response.data.exists && response.data.addedToDb) {
                        setSubmitting(false);
                        history.push("/dk/thank-you-2");
                      } else if (
                        response.data.exists &&
                        !response.data.addedToDb
                      ) {
                        setSubmitting(false);
                        history.push("/already-registered");
                      } else {
                        setSubmitting(false);
                        history.push("/something-went-wrong");
                      }
                    });
                  //SEND POST REQUEST TO API
                  // axios
                  //   .post("http://localhost:9000/testAPI", data)
                  //   .then((response) => {
                  //     console.log(response);
                  //   })
                  //   .catch((error) => {
                  //     console.log(error);
                  //   });

                  //setSubmitting(false);
                  //history.push("/thankyou");
                }}
              >
                {({ values, errors, isSubmitting, isValid }) => (
                  <Form autoComplete="off">
                    <Paper className={classes.topPaper}>
                      <Typography variant="h5" gutterBottom>
                        Exhibitor registration - Sign&Print Denmark 2024
                      </Typography>
                      <Typography variant="body1" gutterBottom></Typography>
                    </Paper>
                    <Paper className={classes.topPaper}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Typography variant="h6" gutterBottom>
                            Choose your company
                          </Typography>
                          <Field
                            options={options}
                            label="Company"
                            name="company"
                            component={SelectFormField}
                          />
                        </Grid>

                        <FieldArray name="attendees">
                          {({ push, remove }) => (
                            <React.Fragment>
                              <Grid item>
                                <Typography variant="h6" gutterBottom>
                                  Attending exhibitors
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  Note that each exhibitor must have a unique
                                  e-mail address.
                                </Typography>
                              </Grid>

                              {values.attendees.map((_, index) => (
                                <Card
                                  className={classes.dynamicCard}
                                  key={index}
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      item
                                      key={index}
                                      spacing={2}
                                    >
                                      <Grid
                                        item
                                        container
                                        spacing={2}
                                        xs={12}
                                        sm="auto"
                                      >
                                        <Grid item xs={10}>
                                          <Typography>
                                            Exhibitor {index + 1}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                          <Button
                                            className={classes.dynamicButton}
                                            variant="contained"
                                            color="secondary"
                                            disabled={isSubmitting}
                                            onClick={() => remove(index)}
                                          >
                                            Delete
                                          </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Field
                                            fullWidth
                                            name={`attendees.${index}.firstName`}
                                            component={TextFormField}
                                            label="First Name"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Field
                                            fullWidth
                                            name={`attendees[${index}].lastName`}
                                            component={TextFormField}
                                            label="Last Name"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Field
                                            fullWidth
                                            name={`attendees[${index}].email`}
                                            component={TextFormField}
                                            label="E-mail"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Field
                                            fullWidth
                                            name={`attendees[${index}].phone`}
                                            component={TextFormField}
                                            label="Phone"
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              ))}

                              <Grid item>
                                {typeof errors.attendees === "string" ? (
                                  <Typography color="error">
                                    {errors.attendees}
                                  </Typography>
                                ) : null}
                              </Grid>

                              <Grid item>
                                <Button
                                  disabled={isSubmitting}
                                  variant="contained"
                                  onClick={() => push(emptyAttendeeList)}
                                >
                                  Add exhibitor
                                </Button>
                              </Grid>
                            </React.Fragment>
                          )}
                        </FieldArray>

                        <Grid item>
                          <Field
                            name="termsAndConditions"
                            type="checkbox"
                            component={BannerCheckbox}
                            color="primary"
                            label="I accept the terms and conditions"
                            subLabel="The exhibitors above agree that their data can be used to be contacted by the organizer if needed. The data will also be used in anonymized form for marketing purposes.
                "
                          />
                        </Grid>

                        <Grid item>
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="primary"
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="0.9rem" />
                              ) : undefined
                            }
                          >
                            {isSubmitting ? "Submitting" : "Submit"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>

                    {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default DkExhibitorForm;
