import { getIn } from "formik";
import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export const SelectFormField = ({ field, form, label, options, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  return (
    <FormControl fullWidth variant="outlined" error={!!errorText}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select label={label} {...field} {...props}>
        {options.map((op) => (
          <MenuItem key={op.value} value={op.value}>
            {op.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

// ---------Setup---------
//
//
// <Field
//   options={[
//     { label: "Dog", value: "dog" },
//     { label: "Cat", value: "cat" },
//   ]}
//   label="Pet"
//   name="pet"
//   component={SelectFormField}
// />
