import React from "react";
import { Button, Typography, Container } from "@material-ui/core";
import useStyles from "../styles";
import { Link } from "@material-ui/core";

const NoBanPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.bodyStyle}>
      <Container maxWidth="md">
        <Typography
          className={classes.typographyStyle}
          variant="h5"
          align="center"
          gutterBottom
        >
          Oops, du har registrert en leverandøradresse.
        </Typography>
        <Typography
          className={classes.typographyStyle}
          variant="body1"
          align="center"
          gutterBottom
        >
          Er du leverandør og ønsker å være på messen? Da er du velkommen til å
          reservere en stand.
        </Typography>
        <Typography
          className={classes.typographyStyle}
          align="center"
          gutterBottom
        >
          <Button
            href="https://www.sipp.no/hallplan/"
            variant="contained"
            color="primary"
          >
            Bestill stand her
          </Button>
        </Typography>
      </Container>
    </div>
  );
};

export default NoBanPage;
