import React from "react";
import Box from "@material-ui/core/Box";
import { getIn } from "formik";
import {
  FormControl,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { bannerCheckboxStylesHook } from "@mui-treasury/styles/checkbox/banner";
import useStyles from "../styles";

const BannerCheckbox = ({ field, form, label, name, subLabel, ...props }) => {
  const checkboxStyles = bannerCheckboxStylesHook.useCheckbox();
  const formControlLabelStyles = bannerCheckboxStylesHook.useFormControlLabel();
  const subtitleStyles = bannerCheckboxStylesHook.useSubtitle();
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  const classes = useStyles();
  return (
    <Box display="grid">
      <FormControl
        className={classes.formControlLabelCustom}
        error={!!errorText}
      >
        <FormControlLabel
          className={classes.formControlLabelCustom}
          classes={formControlLabelStyles}
          control={
            <Checkbox
              className={classes.formControlLabelCustom}
              name={name}
              color="primary"
              classes={checkboxStyles}
              {...field}
              {...props}
            />
          }
          label={
            <>
              {label}
              <Typography component="span" classes={subtitleStyles}>
                {subLabel}
              </Typography>
              <FormHelperText>{errorText}</FormHelperText>
            </>
          }
        />
      </FormControl>
    </Box>
  );
};

export default BannerCheckbox;
