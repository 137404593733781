import React from "react";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import sopse from "../images/scandinavia-2023.png";
import sopdk from "../images/sopdk-2022.png";
import sopno from "../images/sopp-no.png";
import { useHistory } from "react-router-dom";
import {
  CardContent,
  Card,
  Typography,
  Paper,
  Container,
  Grid,
  CardActions,
  CardMedia,
} from "@material-ui/core";

const ChooseExhibition = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.startPage}>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Typography variant="h4">Coming events</Typography>
          <Grid container spacing={2}>
            {/*<Grid item xs={12} md={6}>
              <Card className={classes.eventCard}>
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  image={sopse}
                  alt="scandinavia2023"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Sign&Print Scandinavia 2023
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    19-21 September 2023, Kistamässan
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => {
                      history.push("/se/register");
                    }}
                    className={classes.chooseButton}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Register
                  </Button>
                  <Button
                    href="https://www.signochprint.se/"
                    className={classes.chooseButton}
                    variant="contained"
                    size="medium"
                  >
                    Read more
                  </Button>
                </CardActions>
              </Card>
            </Grid>*/}
            <Grid item xs={12} s={6} md={6}>
              <Card className={classes.eventCard}>
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  image={sopdk}
                  alt="scandinavia2022"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Sign Print & Pack 2024
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    18-19 September 2024, Odense Congress Center
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => {
                      history.push("/dk/register");
                    }}
                    className={classes.chooseButton}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Register
                  </Button>
                  <Button
                    href="https://sipp.dk/"
                    className={classes.chooseButton}
                    variant="contained"
                    size="medium"
                  >
                    Read more
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} s={6} md={6}>
              <Card className={classes.eventCard}>
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  image={sopno}
                  alt="sign-print-promotion-2023"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Sign, Print & Promotion 2025
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    19-20 March 2025, X Meeting Point Norway
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => {
                      history.push("/no/register");
                    }}
                    className={classes.chooseButton}
                    variant="contained"
                    size="medium"
                    color="primary"
                  >
                    Register
                  </Button>
                  <Button
                    href="https://sipp.no/"
                    className={classes.chooseButton}
                    variant="contained"
                    size="medium"
                  >
                    Read more
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default ChooseExhibition;
