import { useField } from "formik";
import React from "react";
import { Radio, FormControlLabel } from "@material-ui/core";

export const RadioField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <div>
      <FormControlLabel
        {...field}
        control={<Radio color="primary" />}
        label={label}
        error={errorText}
      />
    </div>
  );
};
