import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import "react-phone-input-2/lib/material.css";
import {
  FormGroup,
  FormControl,
  CardContent,
  Card,
  Typography,
  Paper,
  Container,
  Grid,
  Link,
} from "@material-ui/core";
import * as yup from "yup";
import { TextFormField } from "../common/textFormField";
import { SelectFormField } from "../common/selectFormField";
import { RadioField } from "../common/radioField";
import BannerCheckbox from "../common/bannerCheckbox";
import ErrorText from "../common/errorText";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useStyles from "../styles";

const exhibitionId = "sopdk793058";
const userCategory = "visitor";

const options = [
  { label: "Promotion", value: "promotion" },
  { label: "Materialer", value: "material" },
  { label: "Emballage", value: "förpackning" },
  { label: "Serigrafi", value: "screen" },
  { label: "Storformat", value: "storformat" },
  { label: "Skilt", value: "skylt" },
  { label: "Tryk", value: "tryckeri" },
  { label: "Konvertering", value: "konvertering" },
  { label: "Bogbinderi", value: "bokbinderi" },
  { label: "Færdiggøring", value: "färdiggörning" },
  { label: "Digitaltryk", value: "Digitaltryck" },
];

const countryCodeRegex = /^(\+?\d{1,3}|\d{1,4})$/;

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Indtast en gyldig email addresse")
    .required("Feltet er obligatorisk"),
  firstName: yup
    .string()
    .min(2, "Fornavnet skal indeholde mindst 2 tegn")
    .max(50, "Fornavnet kan maksimalt indeholde 50 tegn")
    .required("Feltet er obligatorisk"),
  lastName: yup
    .string()
    .min(2, "Efternavnet skal indeholde mindst 2 tegn")
    .max(50, "Efternavnet kan maksimalt indeholde 50 tegn")
    .required("Feltet er obligatorisk"),
  phone: yup
    .string()
    .min(5, "Indtast et gyldigt telefonnummer")
    .max(20, "Indtast et gyldigt telefonnummer")
    .required("Feltet er obligatorisk"),
  countryCode: yup
    .string()
    .min(2, "Landekoden skal indeholde mindst ét ​​ciffer")
    .max(4, "Landekoden må maksimalt indeholde 3 cifre")
    .matches(countryCodeRegex, "Indtast en gyldig landekode")
    .required("Feltet er obligatorisk"),
  company: yup
    .string()
    .min(2, "Firmanavnet skal indeholde mindst 2 tegn")
    .max(50, "Firmanavnet kan maksimalt indeholde 50 tegn")
    .required("Feltet er obligatorisk"),
  addressField: yup
    .string()
    .min(2, "Adresselinjen skal indeholde mindst 2 tegn")
    .max(60, "Adressefeltet kan maksimalt indeholde 60 tegn")
    .required("Feltet er obligatorisk"),
  zipcode: yup
    .string()
    .min(3, "Indtast et gyldigt postnummer")
    .max(16, "Postnummeret kan maksimalt indeholde 16 tegn")
    .required("Feltet er obligatorisk"),
  city: yup
    .string()
    .min(2, "Bynavnet skal indeholde mindst 2 tegn")
    .max(40, "Bynavnet kan maksimalt indeholde 40 tegn")
    .required("Feltet er obligatorisk"),
  decisionMaker: yup.string().required("Vælg et alternativ"),
  investing: yup.string().required("Vælg et alternativ"),
  interests: yup.array().min(1, "Vælg mindst ét alternativ"),
  position: yup.string().required("Feltet er obligatorisk"),
  terms: yup.boolean().oneOf([true], "Vilkårene må godkendes"),
});

function formatDate() {
  return moment().format("YYYY-MM-DD  HH:mm:ss");
}

const DkRegistrationForm = () => {
  const classes = useStyles();
  let history = useHistory();
  return (
    <Formik
      initialValues={{
        userCategory: "",
        exhibitionId: "",
        registeredAtDate: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        countryCode: "",
        company: "",
        addressField: "",
        zipcode: "",
        city: "",
        position: "",
        decisionMaker: "",
        investing: "",
        interests: [],
        terms: false,
        newsletter: false,
        //        group: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true);
        data.registeredAtDate = formatDate();
        data.firstName = data.firstName.trim();
        data.lastName = data.lastName.trim();
        data.email = data.email.trim();
        data.exhibitionId = exhibitionId;
        data.userCategory = userCategory;
        //http://localhost:9000/db
        //https://api.sipp.no/db
        //https://api.emjoevents.com/db
        axios.post("https://api.emjoevents.com/db", data).then((response) => {
          //console.log("Here is the response visitor" + response.data);
          if (
            !response.data.exists &&
            response.data.addedToDb &&
            !response.data.banned
          ) {
            setSubmitting(false);
            history.push("/dk/thank-you");
          } else if (
            response.data.exists &&
            !response.data.addedToDb &&
            !response.data.banned
          ) {
            setSubmitting(false);
            history.push("/dk/already-registered");
          } else if (
            !response.data.exists &&
            !response.data.addedToDb &&
            response.data.banned
          ) {
            setSubmitting(false);
            history.push("/dk/oops");
          } else {
            setSubmitting(false);
            history.push("/something-went-wrong");
          }
        });
        //SEND POST REQUEST TO API
        // axios
        //   .post("http://localhost:9000/testAPI", data)
        //   .then((response) => {
        //     console.log(response);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });

        //setSubmitting(false);
        //history.push("/thankyou");
      }}
    >
      {({ values, isSubmitting, errors }) => {
        return (
          <div className={classes.form}>
            <Container maxWidth="md">
              <Paper className={classes.topPaper}>
                <Typography variant="h5" gutterBottom>
                  Velkommen til Sign Print & Pack Denmark 2024
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Sign Print & Pack Denmark ønsker alle kunder velkommen -
                  direkte og indirekte - til utstillerne.
                </Typography>
                <Typography>
                  Er du leverandør og vil være med på messen? Så er du velkommen
                  at reservere en stand &nbsp;
                  <Link href="https://sipp.dk/halplan/" underline="hover">
                    her
                  </Link>
                  .
                </Typography>
              </Paper>
              <Paper className={classes.paper}>
                <Form>
                  <Typography variant="h5" gutterBottom>
                    Registrering til Sign Print & Pack Denmark 2024
                  </Typography>
                  <Typography variant="body2">
                    Husk at du ikke kan registrere flere besøgende i samme
                    e-mail.
                  </Typography>

                  <Field
                    name="email"
                    label="E-mail"
                    component={TextFormField}
                  />
                  <Field
                    name="firstName"
                    label="Fornavn"
                    component={TextFormField}
                  />
                  <Field
                    name="lastName"
                    label="Efternavn"
                    component={TextFormField}
                  />
                  <Field
                    name="company"
                    label="Virksomhed"
                    component={TextFormField}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={3}>
                      <Field
                        name="countryCode"
                        label="Landekode"
                        component={TextFormField}
                        autoComplete="new-password"
                        helperText="eks. +45"
                        type="tel"
                      />
                    </Grid>
                    <Grid item xs={7} sm={9}>
                      <Field
                        name="phone"
                        label="Telefonnummer"
                        component={TextFormField}
                        autoComplete="new-password"
                        type="number"
                        className={classes.input}
                      />
                    </Grid>
                  </Grid>

                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="addressField"
                            label="Adresse"
                            component={TextFormField}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Field
                            name="zipcode"
                            label="Postnummer"
                            component={TextFormField}
                          />
                        </Grid>
                        <Grid item xs={6} sm={9}>
                          <Field
                            name="city"
                            label="By"
                            component={TextFormField}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Field
                    options={[
                      {
                        label: "Direktör / Ejer / Bestyrelse",
                        value: "vd-ägare-styrelse",
                      },
                      { label: "Produktionsledelse", value: "produktionschef" },
                      {
                        label: "Marketing / Kommunikation",
                        value: "marknad-kommunikation",
                      },
                      { label: "IT", value: "it-chef" },
                      {
                        label: "Kreativ / Formgiver",
                        value: "kreativ-formgiver",
                      },
                      {
                        label: "Prepress",
                        value: "prepress-medarbetare",
                      },
                      { label: "Tryk", value: "tryckeri" },
                      { label: "Færdiggørelse", value: "efterbehandling" },
                      { label: "Logistik", value: "logistik" },
                      { label: "Salg", value: "säljare" },
                    ]}
                    label="Position"
                    name="position"
                    component={SelectFormField}
                  />
                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <Typography variant="h5">Jeg...</Typography>
                      <RadioField
                        name="decisionMaker"
                        type="radio"
                        label="Er beslutningstager"
                        value="yes"
                      />
                      <RadioField
                        name="decisionMaker"
                        type="radio"
                        label="Påvirker beslutninger"
                        value="influence"
                      />
                      <RadioField
                        name="decisionMaker"
                        type="radio"
                        label="Påvirker ikke beslutninger"
                        value="no"
                      />
                      <ErrorMessage
                        name="decisionMaker"
                        component={ErrorText}
                      />
                    </CardContent>
                  </Card>
                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <Typography variant="h5">Min virksomhed...</Typography>
                      <RadioField
                        name="investing"
                        type="radio"
                        label="Planlægger investeringer i 2024"
                        value="planToInvest2024"
                      />
                      <RadioField
                        name="investing"
                        type="radio"
                        label="Planlægger investeringer i 2025"
                        value="planToInvest2025"
                      />
                      <RadioField
                        name="investing"
                        type="radio"
                        label="Planlægger ikke at investere"
                        value="dontPlanToInvest"
                      />
                      <ErrorMessage name="investing" component={ErrorText} />
                    </CardContent>
                  </Card>
                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <FormControl>
                        <Typography variant="h5">Interesser</Typography>
                        <Typography variant="subtitle1">
                          Hvad vil du se på messen? Flere kryds kan sættes
                        </Typography>
                        <FormGroup className={classes.formgroup}>
                          {options.map((opt) => (
                            <Field
                              type="checkbox"
                              color="primary"
                              component={CheckboxWithLabel}
                              name="interests"
                              key={opt.value}
                              value={opt.value}
                              Label={{ label: opt.label }}
                            />
                          ))}
                        </FormGroup>
                        <ErrorMessage name="interests" component={ErrorText} />
                      </FormControl>
                    </CardContent>
                  </Card>
                  <div></div>
                  <Field
                    className={classes.checkbox}
                    name="terms"
                    label="Godkend villkor"
                    subLabel="Jeg accepterer, at mine data kan bruges til at modtage email fra arrangøren. Jeg kan også kontaktes af udstillere og virksomheder, som har scannet mit deltagerskilt på messen. Mine data kan også anvendes i anonym form og bruges til markedsføringsformål. Oversigtsbilleder taget på messen kan bruges til redaktionelt indhold og markedsføringsformål af arrangøren.
                "
                    component={BannerCheckbox}
                  />
                  <div></div>
                  <Button
                    className={classes.button}
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Send
                  </Button>
                  <div></div>
                  {/* <Field
                name="group"
                label="Select your interests"
                options={[
                  { label: "Interest1", value: "interest1" },
                  { label: "Interest2", value: "interest2" },
                  { label: "Interest3", value: "interest3" },
                ]}
                component={GroupCheckbox}
              /> */}
                  {/* <h3>Values</h3>
                  <pre>{JSON.stringify(values, 0, 2)}</pre>
                  <pre>{JSON.stringify(errors, 0, 2)}</pre> */}
                </Form>
              </Paper>
            </Container>
          </div>
        );
      }}
    </Formik>
  );
};

export default DkRegistrationForm;
