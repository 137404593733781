import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import "react-phone-input-2/lib/material.css";
import {
  FormGroup,
  FormControl,
  CardContent,
  Card,
  Typography,
  Paper,
  Container,
  Grid,
  Link,
} from "@material-ui/core";
import * as yup from "yup";
import { TextFormField } from "../common/textFormField";
import { SelectFormField } from "../common/selectFormField";
import { RadioField } from "../common/radioField";
import BannerCheckbox from "../common/bannerCheckbox";
import ErrorText from "../common/errorText";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useStyles from "../styles";

const exhibitionId = "sopno399243";
const userCategory = "visitor";

const options = [
  { label: "Promotion", value: "promotion" },
  { label: "Materialer", value: "materialer" },
  { label: "Emballasje", value: "emballasje" },
  { label: "Screen/Silke", value: "screen-silke" },
  { label: "Storformat", value: "storformat" },
  { label: "Skilt", value: "skilt" },
  { label: "Trykkeri", value: "trykkeri" },
  { label: "Konvertering", value: "konvertering" },
  { label: "Bokbinderi", value: "bokbinderi" },
  { label: "Ferdiggjøring", value: "ferdiggjøring" },
  { label: "Digitaltrykk", value: "Digitaltrykk" },
];

const countryCodeRegex = /^(\+?\d{1,3}|\d{1,4})$/;

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Skriv inn en gyldig e-postadresse")
    .required("Feltet er obligatorisk"),
  firstName: yup
    .string()
    .min(2, "Fornavnet må inneholde minst 2 tegn")
    .max(50, "Fornavnet må være mindre enn 50 tegn")
    .required("Feltet er obligatorisk"),
  lastName: yup
    .string()
    .min(2, "Etternavnet må inneholde minst 2 tegn")
    .max(50, "Etternavnet må være mindre enn 50 tegn")
    .required("Feltet er obligatorisk"),
  phone: yup
    .string()
    .min(5, "Skriv inn et gyldig telefonnummer")
    .max(20, "Skriv inn et gyldig telefonnummer")
    .required("Feltet er obligatorisk"),
  countryCode: yup
    .string()
    .min(2, "Landskoden må inneholde minst ett siffer")
    .max(4, "Landskoden må inneholde maksimalt 3 sifre")
    .matches(countryCodeRegex, "Skriv inn en gyldig landskode")
    .required("Feltet er obligatorisk"),
  company: yup
    .string()
    .min(2, "Firmanavnet må inneholde minst 2 tegn")
    .max(50, "Firmanavnet kan inneholde maksimalt 50 tegn")
    .required("Feltet er obligatorisk"),
  addressField: yup
    .string()
    .min(2, "Adressefeltet må inneholde minst 2 tegn")
    .max(60, "Adressefeltet kan inneholde maksimalt 60 tegn")
    .required("Feltet er obligatorisk"),
  zipcode: yup
    .string()
    .min(3, "Skriv inn et gyldig postnummer")
    .max(16, "Postnummeret kan inneholde maksimalt 16 tegn")
    .required("Feltet er obligatorisk"),
  city: yup
    .string()
    .min(2, "Navnet på byen må inneholde minst 2 tegn")
    .max(40, "Navnet på byen kan inneholde maksimalt 40 tegn")
    .required("Feltet er obligatorisk"),
  decisionMaker: yup.string().required("Velg et alternativ"),
  investing: yup.string().required("Velg et alternativ"),
  interests: yup.array().min(1, "Velg minst ett alternativ"),
  position: yup.string().required("Feltet er obligatorisk"),
  terms: yup.boolean().oneOf([true], "Vilkårene må godkjennes"),
});

function formatDate() {
  return moment().format("YYYY-MM-DD  HH:mm:ss");
}

const NoRegistrationForm = () => {
  const classes = useStyles();
  let history = useHistory();
  return (
    <Formik
      initialValues={{
        userCategory: "",
        exhibitionId: "",
        registeredAtDate: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        countryCode: "",
        company: "",
        addressField: "",
        zipcode: "",
        city: "",
        position: "",
        decisionMaker: "",
        investing: "",
        interests: [],
        terms: false,
        newsletter: false,
        //        group: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true);
        data.registeredAtDate = formatDate();
        data.firstName = data.firstName.trim();
        data.lastName = data.lastName.trim();
        data.email = data.email.trim();
        data.exhibitionId = exhibitionId;
        data.userCategory = userCategory;
        //http://localhost:9000/db
        //https://api.sipp.no/db
        //https://api.emjoevents.com/db
        axios.post("https://api.emjoevents.com/db", data).then((response) => {
          //console.log("Here is the response visitor" + response.data);
          if (
            !response.data.exists &&
            response.data.addedToDb &&
            !response.data.banned
          ) {
            setSubmitting(false);
            history.push("/no/thank-you");
          } else if (
            response.data.exists &&
            !response.data.addedToDb &&
            !response.data.banned
          ) {
            setSubmitting(false);
            history.push("/no/already-registered");
          } else if (
            !response.data.exists &&
            !response.data.addedToDb &&
            response.data.banned
          ) {
            setSubmitting(false);
            history.push("/no/oops");
          } else {
            setSubmitting(false);
            history.push("/something-went-wrong");
          }
        });
        //SEND POST REQUEST TO API
        // axios
        //   .post("http://localhost:9000/testAPI", data)
        //   .then((response) => {
        //     console.log(response);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });

        //setSubmitting(false);
        //history.push("/thankyou");
      }}
    >
      {({ values, isSubmitting, errors }) => {
        return (
          <div className={classes.form}>
            <Container maxWidth="md">
              <Paper className={classes.topPaper}>
                <Typography variant="h5" gutterBottom>
                  Velkommen til Sign, Print & Promotion 2025
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Sign, Print & Promotion ønsker alle kunder velkommen - direkte
                  og indirekte - til utstillerne.
                </Typography>
                <Typography>
                  Er du leverandør og ønsker å være på messen? Da er du
                  velkommen til å reservere en stand&nbsp;
                  <Link href="https://www.sipp.no/hallplan/" underline="hover">
                    her
                  </Link>
                  .
                </Typography>
              </Paper>
              <Paper className={classes.paper}>
                <Form>
                  <Typography variant="h5" gutterBottom>
                    Registrering til Sign, Print & Promotion 2025
                  </Typography>
                  <Typography variant="body2">
                    Husk at du ikke kan registrere flere besøkende til samme
                    e-post.
                  </Typography>

                  <Field
                    name="email"
                    label="E-post"
                    component={TextFormField}
                  />
                  <Field
                    name="firstName"
                    label="Fornavn"
                    component={TextFormField}
                  />
                  <Field
                    name="lastName"
                    label="Etternavn"
                    component={TextFormField}
                  />
                  <Field
                    name="company"
                    label="Firmanavn"
                    component={TextFormField}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={3}>
                      <Field
                        name="countryCode"
                        label="Landskode"
                        component={TextFormField}
                        autoComplete="new-password"
                        helperText="eks. +47"
                        type="tel"
                      />
                    </Grid>
                    <Grid item xs={7} sm={9}>
                      <Field
                        name="phone"
                        label="Telefonnummer"
                        component={TextFormField}
                        autoComplete="new-password"
                        type="number"
                        className={classes.input}
                      />
                    </Grid>
                  </Grid>

                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="addressField"
                            label="Adresse"
                            component={TextFormField}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Field
                            name="zipcode"
                            label="Postnummer"
                            component={TextFormField}
                          />
                        </Grid>
                        <Grid item xs={6} sm={9}>
                          <Field
                            name="city"
                            label="By"
                            component={TextFormField}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Field
                    options={[
                      {
                        label: "Direktör / Ejer / Bestyrelse",
                        value: "vd-ägare-styrelse",
                      },
                      { label: "Produktionsledelse", value: "produktionschef" },
                      {
                        label: "Marketing / Kommunikation",
                        value: "marknad-kommunikation",
                      },
                      { label: "IT", value: "it-chef" },
                      {
                        label: "Kreativ / Formgiver",
                        value: "kreativ-formgiver",
                      },
                      {
                        label: "Prepress",
                        value: "prepress-medarbetare",
                      },
                      { label: "Tryk", value: "tryckeri" },
                      { label: "Færdiggørelse", value: "efterbehandling" },
                      { label: "Logistik", value: "logistik" },
                      { label: "Salg", value: "säljare" },
                    ]}
                    label="Position"
                    name="position"
                    component={SelectFormField}
                  />
                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <Typography variant="h5">Jeg...</Typography>
                      <RadioField
                        name="decisionMaker"
                        type="radio"
                        label="Er beslutningstager"
                        value="yes"
                      />
                      <RadioField
                        name="decisionMaker"
                        type="radio"
                        label="Påvirker beslutninger"
                        value="influence"
                      />
                      <RadioField
                        name="decisionMaker"
                        type="radio"
                        label="Påvirker ikke beslutninger"
                        value="no"
                      />
                      <ErrorMessage
                        name="decisionMaker"
                        component={ErrorText}
                      />
                    </CardContent>
                  </Card>
                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <Typography variant="h5">Mitt firma...</Typography>
                      <RadioField
                        name="investing"
                        type="radio"
                        label="Planlegger investeringer i 2025"
                        value="planToInvest2025"
                      />
                      <RadioField
                        name="investing"
                        type="radio"
                        label="Planlegger investeringer i 2026"
                        value="planToInvest2026"
                      />
                      <RadioField
                        name="investing"
                        type="radio"
                        label="Planlegger ikke å investere"
                        value="dontPlanToInvest"
                      />
                      <ErrorMessage name="investing" component={ErrorText} />
                    </CardContent>
                  </Card>
                  <Card className={classes.groupCheckbox}>
                    <CardContent>
                      <FormControl>
                        <Typography variant="h5">Interesser</Typography>
                        <Typography variant="subtitle1">
                          Hva vil du se på messen? Du kan velge flere
                          alternativer
                        </Typography>
                        <FormGroup className={classes.formgroup}>
                          {options.map((opt) => (
                            <Field
                              type="checkbox"
                              color="primary"
                              component={CheckboxWithLabel}
                              name="interests"
                              key={opt.value}
                              value={opt.value}
                              Label={{ label: opt.label }}
                            />
                          ))}
                        </FormGroup>
                        <ErrorMessage name="interests" component={ErrorText} />
                      </FormControl>
                    </CardContent>
                  </Card>
                  <div></div>
                  <Field
                    className={classes.checkbox}
                    name="terms"
                    label="Godta vilkårene"
                    subLabel="Jeg godkjenner at mine opplysninger kan benyttes for å motta utsendelser fra arrangøren. Jeg kan også bli kontaktet av utstillere og selskaper som har skannet min besøksbrikke i løpet av messen. Mine opplysninger kan også sammenstilles i anonym form i markedsøyemed. Oversiktsbilder som tas under messens gang kan benyttes i redksjonelt innhold og markedsføringsøyemed av arrangøren.
                "
                    component={BannerCheckbox}
                  />
                  <div></div>
                  <Button
                    className={classes.button}
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    Send
                  </Button>
                  <div></div>
                  {/* <Field
                name="group"
                label="Select your interests"
                options={[
                  { label: "Interest1", value: "interest1" },
                  { label: "Interest2", value: "interest2" },
                  { label: "Interest3", value: "interest3" },
                ]}
                component={GroupCheckbox}
              /> */}
                  {/* <h3>Values</h3>
                  <pre>{JSON.stringify(values, 0, 2)}</pre>
                  <pre>{JSON.stringify(errors, 0, 2)}</pre> */}
                </Form>
              </Paper>
            </Container>
          </div>
        );
      }}
    </Formik>
  );
};

export default NoRegistrationForm;
