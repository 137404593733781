import { Container, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../styles";

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Container className={classes.footer} maxWidth="sm">
        <Typography
          className={classes.footerText}
          variant="subtitle2"
          align="center"
        >
          AGI Publishing House AB | Altonagatan 5 | 211 38 Malmö | Sverige
        </Typography>
      </Container>
    </div>
  );
};

export default Footer;
